let cache = null;
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://dms2.houstoneng.net";

export function trimColumns(columnData, appConfig) {
  var disabled = Object.values(appConfig)
    .filter((obj) => {
      return obj.enabled === false;
    })
    .map(function (item) {
      return item.description;
    });

  var adminDisabled = Object.values(appConfig.ADMIN)
    .filter((obj) => {
      return obj.enabled === false;
    })
    .map(function (item) {
      return item.description;
    });

  disabled = disabled.concat(adminDisabled);

  for (var i = columnData.length - 1; i >= 0; i--) {
    if (disabled.includes(columnData[i].label)) {
      columnData.splice(i, 1);
    }
  }

  return columnData;
}

export default function requestConfig(token) {
  if (!cache) {
    let URL = isLocalhost ? LOCAL_URL : PROD_URL;
    cache = fetch(URL + "/application/config", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }).then((response) => response.json());
  }

  return cache;
}
