import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../components/common/orm";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@mdi/react";
import { mdiTestTube } from '@mdi/js';
import { mdiTestTubeOff } from '@mdi/js';
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

const waterYear = createSelector((session, id) => {
  let wy = session.WaterYear.filter(
    (y) => y.is_active === true
  ).toRefArray()[0];
  return {
    ...wy,
  };
});

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://dms2.houstoneng.net";

const styles = (theme) => ({
  bulkQAButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px"    
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
  },
  loading: {
    width: "20px !important",
    height: "20px !important",
  },
});

class ImportQAVisibleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
    };
  }

  render() {
    const { classes, data, dataTarget } = this.props;

    let isDisabled = false;
        
    let qasubmitted =
      this.props.data.cy_data[dataTarget + "_qa_visible"] === true ? 0 : 1;

    let disabled =
      !data.cy_data.authorized_reporter ||
      (data.cy_data && !data.cy_data[dataTarget + "_ct"]) ||
      (data.cy_data && data.cy_data[dataTarget + "_submitted"] === "Complete");

    if (disabled) {
      isDisabled = true;
    } else if (this.state.isFetching) {
      isDisabled = true;
    } else if (!this.state.isFetching && !disabled) {
      isDisabled = false;
    }

    return (
      <Button
        className={classes.bulkQAButton}
        onClick={() => this.fetchData()}
        disabled={isDisabled}
        style={isDisabled ? ({
          backgroundColor: "#7ed2f5",
          color: "white",
        }) : ({
          color: "white",
        })}
      >
        {this.state.isFetching ? (          
          qasubmitted ? (
            <>
              <div className={classes.root}>
                <CircularProgress className={classes.loading} />
              </div>
              QA Hidden                
            </>
          ) : (
            <>
              <div className={classes.root}>
                <CircularProgress className={classes.loading} />
              </div>
              QA Visible
            </>
          )
        ) : (
          <>
            {qasubmitted ? (
              <>
                <Icon path={mdiTestTubeOff} size={1} color="white"></Icon>
                QA Hidden                
              </>
            ) : (
              <>
                <Icon path={mdiTestTube} size={1} color="white"></Icon>
                QA Visible
              </>
            )}    
          </>
        )}            
      </Button>
    );
  }

  fetchData = () => {
    //options for apitarget: [reportingstatus,importstatus,combinedstatus]

    let URL = isLocalhost ? LOCAL_URL : PROD_URL;

    const { authState, water_year, dataTarget, dataAction, gsp } = this.props;

    let token = authState && authState.user ? authState.user.auth_token : "";

    this.setState({ ...this.state, isFetching: true });

    let datasetname = "";

    switch (dataTarget) {
      case "gw":
        datasetname = "wellsitemeasurements";
        break;
      case "wq":
        datasetname = "waterqualitymeasurements";
        break;
      case "surf":
        datasetname = "surfacewatermeasurements";
        break;
      case "sub":
        datasetname = "subsidencemeasurements";
        break;      
      case "stream":
        datasetname = "streammonitoring";
        break;
    }

    let visible =
      this.props.data.cy_data[dataTarget + "_qa_visible"] === true ? 0 : 1;

    let payload = {
      datasetname: datasetname,
      qa_visible: visible,
      gsp_id: gsp,
      water_year: water_year.water_year,
    };

    fetch(URL + "/bulkqavisible", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({ ...this.state, isFetching: false });
        this.props.handler(result);
      })
      .catch((e) => {
        console.log(e);
        this.setState({ ...this.state, isFetching: false });
      });
  };
}
ImportQAVisibleButton = connect(
  (state, ownProps) => ({
    water_year: waterYear(state, ownProps),
    authState: state.auth,
  }),
  {}
)(ImportQAVisibleButton);

export default withStyles(styles)(ImportQAVisibleButton);
