import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import HelpOutline from "@material-ui/icons/HelpOutline";
//import SupportIcon from "./icons/SupportIcon";

const styles = theme => ({
  supportIcon: {
    width: "3em",
    height: "3em",
    color: "#fff",
    marginLeft: 32
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center"
  },
  titleContainer2: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    "& path, & circle": {
      stroke: "#fff"
    }
  },
  questionTitle: {
    marginTop: ".6em",
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  nooverflow: {
    overflow: "hidden"
  },
  greyButton: {
    backgroundColor: "#808080",
    color: "#fff"
  },
  centerButtons: {
    justifyContent: "center",
    marginTop: 24
  },
  closeIcon: {
    float: "right",
    color: "#fff",
    marginTop: -16
  }
});

const HelpDialog = ({
  question,
  text,
  classes,
  confirmAction,
  cancelAction,
  confirmText,
  cancelText,
  onClose,
  showHelp,
  title,
  ...other
}) => (
  <Dialog onClose={onClose} {...other} classes={{ paper: classes.nooverflow }}>
    <DialogContent className={classes.titleContainer}>
      {!showHelp && <ErrorOutline className={classes.supportIcon} />}
      {showHelp && <HelpOutline className={classes.supportIcon} />}
      <IconButton
        onClick={onClose}
        className={classes.closeIcon}
        aria-label="Close Dialog"
      >
        <Close />
      </IconButton>
    </DialogContent>
    <DialogContent>
      <Typography variant="h6" className={classes.questionTitle} gutterBottom>
        {question}
      </Typography>
      <DialogContentText>
        {title ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
      </DialogContentText>
      {confirmAction && (
        <DialogActions className={classes.centerButtons}>
          <Button onClick={confirmAction} color="secondary" variant="contained">
            {confirmText ? confirmText : "Ok"}
          </Button>
          <Button
            onClick={cancelAction}
            variant="contained"
            className={classes.greyButton}
            autoFocus
          >
            {cancelText ? cancelText : "Cancel"}
          </Button>
        </DialogActions>
      )}
      {!confirmAction && (
        <DialogActions className={classes.centerButtons}>
          <Button onClick={onClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      )}
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(HelpDialog);
