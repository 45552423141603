import React from "react";
import { Map, TileLayer, WMSTileLayer, Popup } from "react-leaflet";
import { connect } from "react-redux";
import * as fullscreen from "leaflet-fullscreen"; // eslint-disable-line
import { withStyles } from "@material-ui/core";
import CollapsibleLayersControl from "./CollapsibleLayersControl";
import MapLayers from "./MapLayers";
import ZoomExtent from "./ZoomExtent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  WellSite,
  SubsidenceSite,
  SWGWSite,
  StreamSite,
} from "../../wells/models";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import * as app_settings from "../../../app_settings";
import Tooltip from "@material-ui/core/Tooltip";

const allWells = WellSite.selectAll();
const allSub = SubsidenceSite.selectAll();
const allSWGW = SWGWSite.selectAll();
const allStreams = StreamSite.selectAll();

const styles = (theme) => ({
  height: {
    height: "100%",
    margin: "0 !important",
    width: "100% !important",
  },
  iframe: {
    border: "none",
  },
  button: {
    marginTop: 255,
    padding: 0,
    zIndex: "10000",
    display: "flex",
    height: 34,
    width: 34,
    marginLeft: -10,
  },
  buttonWaterLevel: {
    marginTop: 255,
    padding: 0,
    zIndex: "10000",
    display: "flex",
    height: 34,
    width: 34,
    marginLeft: 22,
  },
});

const colors = [
  "",
  { hex: "#CF382D", color: "red" },
  { hex: "#EF8D2F", color: "orange" },
  { hex: "#6DA42E", color: "green" },
  { hex: "#31A2CC", color: "teal" },
  { hex: "#C24EB1", color: "purple" },
  { hex: "#962E34", color: "darkred" },
  { hex: "#095B97", color: "darkblue" },
  { hex: "#717825", color: "lime" },
  { hex: "#593466", color: "darkpurple" },
  { hex: "#406470", color: "royalblue" },
  { hex: "#FE8878", color: "peach" },
  { hex: "#F8CA85", color: "yellow" },
  { hex: "#B7F272", color: "lightgreen" },
  { hex: "#8CD5FF", color: "skyblue" },
  { hex: "#FC8CE6", color: "pink" },
  { hex: "#E57C86", color: "lightred" },
  { hex: "#A4A2A5", color: "gray" },
  { hex: "#2F2D30", color: "black" },
];

let numMapClicks = 0;

const trustedOrigins = ["https://dms2.houstoneng.net"];

const CustomizedDot = (props) => {
  const { cx, cy, r, stroke, payload, value, categoryColors } = props;
  const color =
    payload.qa_visible && !payload.submitted ? "#ff3d41" : "#FFFFFF";

  return (
    <circle
      cx={cx}
      cy={cy}
      r={r}
      stroke={stroke}
      strokeWidth={1}
      fill={color}
    />
  );
};

class DashboardMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waterChart: false,
    };
    this.state = app_settings.map_initial_state;
  }

  wmsIdentify = (e) => {
    const { waterChart } = this.state;
    //wells are a graphic layer so need to add it as default
    var layer_names = ["wells", "subsidencesite", "swgwsite"];

    //only pull layers where identify = true
    Object.keys(e.target._layers).filter((id) => {
      var p = e.target._layers[id].wmsParams;
      if (p !== undefined && p.info !== undefined && p.info.identify === true) {
        layer_names.push(p.layers);
      }
    });

    if (waterChart) layer_names = ["waterchart"];
    var params = {};

    delete params["leaflet"];

    params["layers"] = layer_names;
    params["query_layers"] = layer_names;

    var map = e.target;
    var bounds = map.getBounds();
    var size = map.getSize();
    var crs = map.options.crs;
    var nw = crs.project(bounds.getNorthWest());
    var se = crs.project(bounds.getSouthEast());

    params["request"] = "GetFeatureInfo";
    params["INFO_FORMAT"] = "text/html";
    params["url"] = "https://dms2.houstoneng.net/cgi-bin/mapserv";
    params["map"] = "/var/www/sgmadms/maps/map.map";
    params["version"] = "1.1.1";
    params["pathname"] = "/cgi-bin/mapserv";
    params["service"] = ["WMS"];
    params["srs"] = "EPSG:3857";
    params["request"] = "GetFeatureInfo";
    params["X"] = Math.round(e.containerPoint.x);
    params["Y"] = Math.round(e.containerPoint.y);
    params["width"] = size.x;
    params["height"] = size.y;
    params["bbox"] = [nw.x, se.y, se.x, nw.y].join(",");

    var url = new URL(params["url"]);

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    //this will call var/www/sgmadms/maps.map (which ties into the mapserver)
    //calls the layer that was clicked on which queries the database
    //then ties into the TEMPLATE 'ex.html' file to return the fields that were in that file
    //will need to create a new html file for each new popup model
    map.openPopup(
      "<iframe id='wmsiden' src='" + url + "' style='border:none'></iframe>",
      e.latlng
    );
    if (waterChart) {
      map._popup._contentNode.className = "leaflet-popup-content-chart";
      map._popup.update();
    }
  };

  render() {
    const { zoom, lat, lng, collapsed, waterChart } = this.state;
    const {
      classes,
      wells,
      subsidences,
      swgws,
      streams,
      pageType,
      filtered_data,
      setRef,
    } = this.props;

    var position = [lat, lng];
    var controlPosition = this.props.controlPosition;

    if (!controlPosition) {
      controlPosition = "topleft";
    }

    const features = {
      indicator_well_monitoring_sites: wells.filter(
        (w) => w.gsp_mon_network === "True"
      ),
      well_sites: wells.filter((w) => w.gsp_mon_network === "False"),
      subsidence_monitoring_sites: subsidences,
      surface_water_monitoring_sites: swgws,
      stream_monitoring_sites: streams,
      water_quality_sites: wells.filter(
        (w) => w.wq_monitoring === "True" || w.wq_monitoring === true
      ),
      water_level_sites: wells.filter(
        (w) => w.wl_monitoring === "True" || w.wl_monitoring === true
      ),
    };

    return (
      <>
        <div
          className={
            typeof pageType !== "undefined"
              ? classes.buttonWaterLevel
              : classes.button
          }
          style={{ marginTop: -35 }}
        >
          <button
            className={classes.button}
            style={{ backgroundColor: waterChart ? "#13aff1" : null }}
            onClick={(e) => this.setState({ waterChart: !waterChart })}
          >
            <Tooltip title="Water Level Chart">
              <EqualizerIcon style={{ marginLeft: 4 }} />
            </Tooltip>
          </button>
        </div>
        <Map
          className={classes.height}
          center={position}
          zoom={zoom}
          maxZoom={18}
          onclick={this.wmsIdentify}
          ref={setRef}
          fullscreenControl={{ position: controlPosition }}
        >
          <ZoomExtent position="topleft" zoom={this.state.zoom} />
          <CollapsibleLayersControl
            position="topright"
            controlPosition={controlPosition}
            collapsed={collapsed}
          >
            {app_settings.map_layers
              .filter((l) => l.add === true && l.type === "baselayer")
              .map((l) => (
                <CollapsibleLayersControl.BaseLayer
                  key={l.id}
                  checked={l.checked}
                  name={l.name}
                >
                  <TileLayer
                    info={null}
                    attribution={l.attribution}
                    url={l.url}
                  />
                </CollapsibleLayersControl.BaseLayer>
              ))}

            {pageType &&
              app_settings.map_layers
                .filter(
                  (l) =>
                    l.add === true &&
                    l.type === "maplayer" &&
                    l.page_type === pageType
                )
                .sort((a, b) => a.index - b.index)
                .map((l) => (
                  <CollapsibleLayersControl.Overlay
                    key={l.id}
                    checked={
                      pageType
                        ? pageType === l.page_type || !pageType
                        : l.checked
                    }
                    name={l.title}
                  >
                    <MapLayers
                      info={l}
                      features={
                        pageType === l.page_type
                          ? filtered_data
                          : features[l.type2]
                      }
                      title={l.title}
                      link={l.link}
                      clusterHex={l.color.hex}
                      clusterColor={l.color.rgb}
                      legend={[{ title: l.title, color: l.color.hex }]}
                      markerSize={l.markerSize}
                      markerClick={this.handleChildClick}
                    />
                  </CollapsibleLayersControl.Overlay>
                ))}
            {pageType &&
              app_settings.map_layers
                .filter(
                  (l) =>
                    l.add === true &&
                    l.type === "wmstilelayer" &&
                    l.selector === undefined
                )
                .map((l) => (
                  <CollapsibleLayersControl.Overlay2
                    key={l.id}
                    checked={l.checked}
                    name={l.title}
                  >
                    <WMSTileLayer
                      info={l}
                      layers={l.layers}
                      transparent={l.transparent}
                      opacity={l.opacity}
                      format={l.format}
                      map={l.map}
                      legend={l.legend}
                      url={l.url}
                    />
                  </CollapsibleLayersControl.Overlay2>
                ))}

            {pageType === undefined &&
              app_settings.map_layers
                .filter((l) => l.add === true && l.type === "maplayer")
                .sort((a, b) => a.index - b.index)
                .map((l) => (
                  <CollapsibleLayersControl.Overlay
                    key={l.id}
                    checked={
                      pageType
                        ? pageType === l.page_type || !pageType
                        : l.checked
                    }
                    name={l.title}
                  >
                    <MapLayers
                      info={l}
                      features={
                        pageType === l.page_type
                          ? filtered_data
                          : features[l.type2]
                      }
                      title={l.title}
                      link={l.link}
                      clusterHex={l.color.hex}
                      clusterColor={l.color.rgb}
                      legend={[{ title: l.title, color: l.color.hex }]}
                      markerSize={l.markerSize}
                      markerClick={this.handleChildClick}
                    />
                  </CollapsibleLayersControl.Overlay>
                ))}
            {pageType === undefined &&
              app_settings.map_layers
                .filter((l) => l.add === true && l.type === "wmstilelayer")
                .map((l) => (
                  <CollapsibleLayersControl.Overlay2
                    key={l.id}
                    checked={l.checked}
                    name={l.title}
                  >
                    <WMSTileLayer
                      info={l}
                      layers={l.layers}
                      transparent={l.transparent}
                      opacity={l.opacity}
                      format={l.format}
                      map={l.map}
                      legend={l.legend}
                      url={l.url}
                    />
                  </CollapsibleLayersControl.Overlay2>
                ))}
          </CollapsibleLayersControl>
        </Map>
      </>
    );
  }
}
DashboardMap = connect(
  (state, ownProps) => ({
    wells: allWells(state),
    subsidences: allSub(state),
    swgws: allSWGW(state),
    streams: allStreams(state),
  }),
  {}
)(DashboardMap);
export default withStyles(styles)(DashboardMap);
