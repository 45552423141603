import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";
import moment from "moment";

import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Rectangle,
} from "recharts";

import { Tooltip as ReTip } from "recharts";

import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import PollIcon from "@material-ui/icons/Poll";
import ListIcon from "@material-ui/icons/List";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Select from "../common/Select";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import { saveAs } from "file-saver";
import NotReady from "../common/NotReady";
import ChangeInStorageDetails from "../../api/changeInStorageHOC";

const historicWaterReporting = createSelector((session, options) => {
  let gsp = session.GSP.all().toRefArray();
  let wy = session.WaterYear.all().toRefArray();
  let gws =
    gsp && wy
      ? session.GroundwaterStorage.filter((r) => r.submitted == true)
          .toRefArray()
          .map((water) => {
            return {
              gws_id: water.id,
              ...water,
              ...wy.find((w) => w.id === water.water_year_id),
              ...gsp.find((g) => g.id === water.gsp),
            };
          })
      : [];
  if (gws.length) {
    console.log(gws);
  }
  return gws;
});

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid " + theme.palette.primary.main,
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #696969",
    minWidth: "44px",
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  iconNotSelected: {
    color: "#696969",
  },
  divideButton: {
    marginBottom: 8,
    width: "50%",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
});

const CustomizedBar = (props) => {
  const { cx, cy, r, stroke, payload, value, categoryColors } = props;
  let color = "#FFFFFF";

  switch (props.label) {
    case "Wet":
      color = "#0070c0";
      break;
    case "Above Normal":
      color = "#92c5de";
      break;
    case "Below Normal":
      color = "#bfbfbf";
      break;
    case "Dry":
      color = "#ffc000";
      break;
    case "Critical":
      color = "#c00000";
      break;
  }

  return <Rectangle {...props} fill={color} name={props.label} />;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}: ${payload[0].payload.label}`}</p>
        <p className="intro">{`Annual Change: ${parseInt(payload[0].value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
        <p className="intro">{`Cumulative Change: ${parseInt(
          payload[0].payload.change
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
      </div>
    );
  }

  return null;
};

const AxisFormater = (number) => {
  if (Math.abs(number) > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (Math.abs(number) > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (Math.abs(number) > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};

class GridViewGroundwaterStorage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: "table",
      filter: null,
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 100,
      histdata: [],
      measurements: [],
      y_axis: "surf_elev",
      y_axis_label: "Surface Elevation",
      start_date: null,
      end_date: null,
      min_value: null,
      max_value: null,
    };
  }
  componentDidMount() {}
  columnData() {
    const groundwaterStorageCols = [
      {
        id: "gsp_label",
        numeric: false,
        label: "GSP Organization Name",
        allowSort: true,
      },
      { id: "water_year", numeric: true, label: "Year", allowSort: true },
      { id: "details", numeric: true, label: "Details", allowSort: false },
      {
        id: "gw_stor_ua",
        numeric: true,
        label: "Upper Aquifier",
        allowSort: true,
      },
      {
        id: "gw_stor_la",
        numeric: true,
        label: "Lower Aquifier",
        allowSort: true,
      },
      {
        id: "gw_stor_total",
        numeric: true,
        label: "Total",
        allowSort: true,
      },
      {
        id: "gw_stor_method_notes",
        numeric: false,
        label: "Explain Calculation Method",
        allowSort: true,
      },
      {
        id: "gw_stor_acc_notes",
        numeric: false,
        label: "Explain Calculation Accuracy",
        allowSort: true,
      },
    ];
    return groundwaterStorageCols;
  }

  filterByValue = (array, string) => {
    return array.filter((o) =>
      Object.keys(o).some(
        (k) =>
          o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
      )
    );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const colType = this.props.match.params["id"];
    const columnData = this.columnData(colType);
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.state.histdata.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString + " 12:00:00");
    return d.toLocaleDateString();
  }

  formatTime(timeString) {
    if (!timeString) {
      return null;
    }
    const d = new Date("2000-01-01 " + timeString);
    return d.toLocaleTimeString();
  }

  getMinDate(arr) {
    if (arr.length) {
      return new Date(
        arr.reduce(
          (min, p) =>
            new Date(p.water_year) < min ? new Date(p.water_year) : min,
          new Date(arr[0].water_year)
        )
      );
    } else {
      return null;
    }
  }

  getMaxDate(arr) {
    if (arr.length) {
      return new Date(
        arr.reduce(
          (max, p) =>
            new Date(p.water_year) > max ? new Date(p.water_year) : max,
          new Date(arr[0].water_year)
        )
      );
    } else {
      return null;
    }
  }

  getMinMaxVal(arr, val, site) {
    let value = val === "surf_elev" ? "surf_elev" : "measurement_depth";
    let min = 1000000000,
      max = 0;

    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i][value];
      min = v < min ? v : min;
      max = v > max ? v : max;
    }

    if (value === "surf_elev") {
      if (site.gsp_threshold > max) {
        max = site.gsp_threshold;
      }
      if (
        site.measurable_objective &&
        parseFloat(site.measurable_objective) > max
      ) {
        max = parseFloat(site.measurable_objective);
      }
      if (site.ground_surface_elev > max) {
        max = site.ground_surface_elev;
      }

      if (site.gsp_threshold < min) {
        min = site.gsp_threshold;
      }
      if (
        site.measurable_objective &&
        parseFloat(site.measurable_objective) < min
      ) {
        min = parseFloat(site.measurable_objective);
      }
      if (site.ground_surface_elev < min) {
        min = site.ground_surface_elev;
      }
    }

    if (min && min != 0) {
      min = this.round(min - 5, 0);
    }

    max = this.round(max + 5, 0);

    return { min, max };
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  printChart = () => {
    const { site } = this.state;
    var printWindow = window.open("", "PrintMap", "width=1200,height=850");
    printWindow.document.writeln(
      "DMS Site ID: " +
        site.dms_site_id +
        this.refs.chartContainer.container.innerHTML
    );
    printWindow.document.close();
  };

  handleAPIChange(data) {
    this.setState({ filtered_measurements: data });
  }

  exportData = (data, key) => {
    var huh = "Date,Water Surface Elevation,Water Depth\n";

    data.forEach((d) => {
      huh = `${huh}${d["measurement_date"]},${
        d["surf_elev"] ? d["surf_elev"] : ""
      },${d["measurement_depth"] ? d["measurement_depth"] : ""}\n`;
    });

    var filename = `${this.state.site.dms_site_id}_water_levels.csv`;
    var blob = new Blob([huh], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  };

  render() {
    const { classes, waterhist } = this.props;
    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      filter,
      histdata,
      buttonState,
      filtered_measurements,
    } = this.state;
    if (waterhist && waterhist.length) {
      if (!histdata.length) {
        this.setState({ histdata: waterhist });
      }
    }
    let level1 = "Submitted Groundwater Change in Storage Data";
    let level1link = "/groundwaterstorage";

    var filtered_data;
    if (filter) {
      filtered_data = this.filterByValue(histdata, filter);
    } else {
      filtered_data = histdata;
    }

    if (filtered_measurements === undefined) {
      return (
        <AppContainer authenticated>
          <ChangeInStorageDetails
            apitarget={"annualchange"}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid
            container
            spacing={24}
            className={
              buttonState === "chart" ? classes.mapHeight : classes.gridHeight
            }
          >
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="Submitted Groundwater Change in Storage Data"
                level1link="/groundwaterstorage"
              />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5">
                Submitted Groundwater Change in Storage Data
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Select View</Typography>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4} style={{ backgroundColor: "#ddd" }}>
              <Button
                title="Chart"
                onClick={() => this.setState({ buttonState: "chart" })}
                className={classNames(
                  classes.divideButton,
                  buttonState === "chart"
                    ? classes.buttonSelected
                    : classes.buttonNotSelected
                )}
              >
                <PollIcon
                  className={
                    buttonState === "chart"
                      ? classes.iconSelected
                      : classes.iconNotSelected
                  }
                />
              </Button>
              <Button
                title="Table"
                onClick={() => this.setState({ buttonState: "table" })}
                className={classNames(
                  classes.divideButton,
                  buttonState === "table"
                    ? classes.buttonSelected
                    : classes.buttonNotSelected
                )}
              >
                <ListIcon
                  className={
                    buttonState === "table"
                      ? classes.iconSelected
                      : classes.iconNotSelected
                  }
                />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                buttonState === "chart"
                  ? classes.mapHeight2
                  : classes.gridHeight
              }
            >
              {buttonState === "chart" && (
                <Grid container spacing={8}>
                  {filtered_measurements.length === 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h5" className={classes.centerAlign}>
                        No Measurements Found
                      </Typography>
                    </Grid>
                  )}
                  {filtered_measurements.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <ResponsiveContainer
                          width="100%"
                          height={680}
                          ref="chartContainer"
                        >
                          <ComposedChart
                            data={filtered_measurements}
                            style={{ paddingRight: "2px" }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="water_year" />
                            <YAxis
                              yAxisId="left"
                              dataKey="val"
                              type="number"
                              tickFormatter={AxisFormater}
                            />
                            <YAxis
                              yAxisId="right"
                              orientation="right"
                              dataKey="change"
                              type="number"
                              domain={[-1800000, 0]}
                              allowDataOverflow={true}
                              tickFormatter={AxisFormater}
                            />
                            <Legend
                              payload={[
                                {
                                  value: "Wet",
                                  type: "square",
                                  id: "ID01",
                                  color: "#0070c0",
                                },
                                {
                                  value: "Above Normal",
                                  type: "square",
                                  id: "ID02",
                                  color: "#92c5de",
                                },
                                {
                                  value: "Below Normal",
                                  type: "square",
                                  id: "ID03",
                                  color: "#bfbfbf",
                                },
                                {
                                  value: "Dry",
                                  type: "square",
                                  id: "ID04",
                                  color: "#ffc000",
                                },
                                {
                                  value: "Critical",
                                  type: "square",
                                  id: "ID05",
                                  color: "#c00000",
                                },
                                {
                                  value: "Cumulative Change in Storage",
                                  type: "line",
                                  id: "ID06",
                                  color: "#0070c0",
                                },
                              ]}
                            />
                            <ReferenceLine yAxisId="left" y={0} stroke="#000" />

                            <ReTip content={<CustomTooltip />} />
                            <Bar
                              yAxisId="left"
                              shape={CustomizedBar}
                              dataKey={"val"}
                            />
                            <Line
                              yAxisId="right"
                              type="monotone"
                              dataKey="change"
                              stroke="#0070c0"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {buttonState === "table" && filtered_data && (
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData()}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {filtered_data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.gsp_label}</TableCell>
                            <TableCell>{item.water_year}</TableCell>
                            <TableCell>
                              <Tooltip title={"View Data"}>
                                <Button
                                  className={classes.tableCellIcon}
                                  onClick={() =>
                                    this.props.history.push({
                                      pathname:
                                        "/reporting/gwstorage/" +
                                        item.id +
                                        "/" +
                                        item.gws_id,
                                    })
                                  }
                                >
                                  <Icon
                                    path={mdiEye}
                                    size={1}
                                    color="primary"
                                  ></Icon>{" "}
                                </Button>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <NumberFormat
                                value={item.gw_stor_ua}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            </TableCell>
                            <TableCell>
                              <NumberFormat
                                value={item.gw_stor_la}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            </TableCell>
                            <TableCell>
                              <NumberFormat
                                value={item.gw_stor_total}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            </TableCell>
                            <TableCell>{item.gw_stor_method_notes}</TableCell>
                            <TableCell>{item.gw_stor_acc_notes}</TableCell>
                          </TableRow>
                        ))}
                      {filtered_data.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {filtered_data.length > rowsPerPage && (
                    <TablePagination
                      colSpan={7}
                      count={filtered_data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  )}
                </Paper>
              )}
            </Grid>
          </Grid>
        </AppContainer>
      );
    }
  }
}
GridViewGroundwaterStorage = connect(
  (state, ownProps) => ({
    waterhist: historicWaterReporting(state, ownProps),
    authState: state.auth,
  }),
  {}
)(GridViewGroundwaterStorage);
export default withStyles(styles)(GridViewGroundwaterStorage);
