import React from "react";
import CollapsibleLayersControl from "./CollapsibleLayersControl";
import { Map, Marker, TileLayer, WMSTileLayer } from "react-leaflet";

const colors = [
  "",
  { hex: "#CF382D", color: "red" },
  { hex: "#EF8D2F", color: "orange" },
  { hex: "#6DA42E", color: "green" },
  { hex: "#31A2CC", color: "teal" },
  { hex: "#C24EB1", color: "purple" },
  { hex: "#962E34", color: "darkred" },
  { hex: "#095B97", color: "darkblue" },
  { hex: "#717825", color: "lime" },
  { hex: "#593466", color: "darkpurple" },
  { hex: "#406470", color: "royalblue" },
  { hex: "#FE8878", color: "peach" },
  { hex: "#F8CA85", color: "yellow" },
  { hex: "#B7F272", color: "lightgreen" },
  { hex: "#8CD5FF", color: "skyblue" },
  { hex: "#FC8CE6", color: "pink" },
  { hex: "#E57C86", color: "lightred" },
  { hex: "#A4A2A5", color: "gray" },
  { hex: "#2F2D30", color: "black" }
];

class EditMap extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 36.642,
      lng: -119.817,
      zoom: 5,
      collapsed: false
    };
  }

  render() {
    const { collapsed } = this.state;
    const position = [
      this.props.geometry ? this.props.geometry.coordinates[1] : this.state.lat,
      this.props.geometry ? this.props.geometry.coordinates[0] : this.state.lng
    ];

    const zoom =
      this.props.geometry && !this.props.zoom
        ? 12
        : this.props.zoom
        ? this.props.zoom
        : this.state.zoom;

    return (
      <Map
        className="editmap-container"
        height={this.props.height}
        width={this.props.width}
        center={position}
        zoom={zoom}
      >
        <Marker
          draggable={"true"}
          onDragend={this.props.handler}
          position={position}
        ></Marker>
        <CollapsibleLayersControl
          position="topright"
          controlPosition="topright"
          collapsed={collapsed}
        >
          <CollapsibleLayersControl.BaseLayer name="Esri Topographic">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>
          <CollapsibleLayersControl.BaseLayer checked name="Esri Streets">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>
          <CollapsibleLayersControl.BaseLayer name="Esri Imagery">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>

          <CollapsibleLayersControl.Overlay
            checked
            name="Delta Mendota Subbasin Boundary"
          >
            <WMSTileLayer
              layers="delta_mendota_subbasin"
              transparent="true"
              opacity="1"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              legend={[{ title: "Boundary", color: "#000000" }]}
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </CollapsibleLayersControl.Overlay>
          <CollapsibleLayersControl.Overlay checked name="GSP Boundaries">
            <WMSTileLayer
              layers="GSP_Group"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              legend={[
                { title: "Fresno", color: "#d8bfd8" },
                { title: "Grassland", color: "#98FB98" },
                { title: "Aliso", color: "#ffa07a" },
                { title: "Farmers", color: "#87CEFA" },
                { title: "NCDM", color: "#FFCCE5" },
                { title: "SJREC", color: "#fcab01" }
              ]}
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </CollapsibleLayersControl.Overlay>
          <CollapsibleLayersControl.Overlay name="GSA Boundaries">
            <WMSTileLayer
              layers="GSA_Name"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              legend={[
                { title: "Aliso", color: "#FFCCCC" },
                { title: "Central", color: "#FFE5CC" },
                { title: "Dos Palos", color: "#FFFFCC" },
                { title: "Firebaugh", color: "#E5FFCC" },
                { title: "Gustine", color: "#CCFFCC" },
                { title: "Los Banos", color: "#CCFFE5" },
                { title: "Mendota", color: "#CCFFFF" },
                { title: "Newman", color: "#CCE5FF" },
                { title: "Patterson", color: "#CCCCFF" },
                { title: "DM-II", color: "#E5CCFF" },
                { title: "Farmers", color: "#FFCCFF" },
                { title: "Fresno A", color: "#FFCCE5" },
                { title: "Fresno B", color: "#FF9999" },
                { title: "Grassland", color: "#FFCC99" },
                { title: "Madera", color: "#FFFF99" },
                { title: "Merced", color: "#CCFF99" },
                { title: "Northwestern", color: "#99FF99" },
                { title: "OLWD", color: "#99FFCC" },
                { title: "PID", color: "#99FFFF" },
                { title: "SJRECWA", color: "#99CCFF" },
                { title: "TIWD", color: "#9999FF" },
                { title: "WSID", color: "#CC99FF" },
                { title: "Widren", color: "#FF99FF" }
              ]}
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </CollapsibleLayersControl.Overlay>
        </CollapsibleLayersControl>
      </Map>
    );
  }
}

export default EditMap;
