const map_initial_state = {
  lat: 37.08,
  lng: -120.8,
  zoom: 9,
  collapsed: false,
  showLayerList: true,
};

const map_layers = [
  {
    id: "esri_topographic",
    add: true,
    checked: true,
    type: "baselayer",
    type2: "topographic",
    name: "ESRI Topographic",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_streets",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "street",
    name: "ESRI Streets",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_imagery",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "imagery",
    name: "ESRI Imagery",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "water_level_sites",
    add: true,
    checked: true,
    title: "Water Level Sites",
    type: "maplayer",
    type2: "water_level_sites",
    page_type: "Water Level",
    link: "waterleveldetail/",
    color: {
      hex: "#CF382D",
      rgb: "red",
    },
    markerSize: 8,
    identify: true,
    index: 0,
  },
  {
    id: "water_quality_sites",
    add: true,
    checked: true,
    title: "Water Quality Sites",
    type: "maplayer",
    type2: "water_quality_sites",
    page_type: "Water Quality",
    link: "waterqualitydetail/",
    color: {
      hex: "#6DA42E",
      rgb: "green",
    },
    markerSize: 8,
    identify: true,
    index: 1,
  },
  {
    id: "subsidence_monitoring_sites",
    add: true,
    checked: true,
    title: "Subsidence Monitoring Sites",
    type: "maplayer",
    type2: "subsidence_monitoring_sites",
    page_type: "Subsidence",
    link: "subsidencedetail/",
    color: {
      hex: "#EF8D2F",
      rgb: "orange",
    },
    markerSize: 8,
    identify: true,
    index: 2,
  },
  {
    id: "surface_water_monitoring_sites",
    add: true,
    checked: true,
    title: "Interconnected SW Monitoring Sites",
    type: "maplayer",
    type2: "surface_water_monitoring_sites",
    page_type: "SWGW",
    link: "swgwdetail/",
    color: {
      hex: "#31A2CC",
      rgb: "teal",
    },
    markerSize: 8,
    identify: true,
    index: 3,
  },
  {
    id: "stream_monitoring_sites",
    add: true,
    checked: true,
    title: "Stream Monitoring Sites",
    type: "maplayer",
    type2: "stream_monitoring_sites",
    page_type: "Stream Monitoring",
    link: "streammonitoringdetail/",
    color: {
      hex: "#8CD5FF",
      rgb: "skyblue",
    },
    markerSize: 8,
    identify: true,
    index: 4,
  },
  {
    id: "delta_mendota_subbasin",
    add: true,
    title: "Delta Mendota Subbasin Boundary",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Boundary", color: "rgb(75,75,75)" }],
    layers: "delta_mendota_subbasin",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 5,
  },
  {
    id: "GSP_Group",
    add: true,
    title: "GSP Boundaries",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { title: "Fresno", color: "#d8bfd8" },
      { title: "Grassland", color: "#98FB98" },
      { title: "Aliso", color: "#ffa07a" },
      { title: "Farmers", color: "#87CEFA" },
      { title: "NCDM", color: "#FFCCE5" },
      { title: "SJREC", color: "#fcab01" },
    ],
    layers: "GSP_Group",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 6,
  },
  {
    id: "GSA_Name",
    add: true,
    title: "GSA Boundaries",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { title: "Aliso", color: "#FFCCCC" },
      { title: "Central", color: "#FFE5CC" },
      { title: "Dos Palos", color: "#FFFFCC" },
      { title: "Firebaugh", color: "#E5FFCC" },
      { title: "Gustine", color: "#CCFFCC" },
      { title: "Los Banos", color: "#CCFFE5" },
      { title: "Mendota", color: "#CCFFFF" },
      { title: "Newman", color: "#CCE5FF" },
      { title: "Patterson", color: "#CCCCFF" },
      { title: "DM-II", color: "#E5CCFF" },
      { title: "Farmers", color: "#FFCCFF" },
      { title: "Fresno A", color: "#FFCCE5" },
      { title: "Fresno B", color: "#FF9999" },
      { title: "Grassland", color: "#FFCC99" },
      { title: "Madera", color: "#FFFF99" },
      { title: "Merced", color: "#CCFF99" },
      { title: "Northwestern", color: "#99FF99" },
      { title: "OLWD", color: "#99FFCC" },
      { title: "PID", color: "#99FFFF" },
      { title: "SJRECWA", color: "#99CCFF" },
      { title: "TIWD", color: "#9999FF" },
      { title: "WSID", color: "#CC99FF" },
      { title: "Widren", color: "#FF99FF" },
    ],
    layers: "GSA_Name",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 7,
  },
  {
    id: "spring_2013_upper",
    add: true,
    title: "Spring 2013",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        color: "rgb(45,41,160)",
        title: "Water Level Contours - Spring 2013",
      },
    ],
    layers: "spring_2013_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 8,
  },
  {
    id: "fall_2013_upper",
    add: true,
    title: "Fall 2013",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        color: "rgb(45,41,160)",
        title: "Water Level Contours - Fall 2013",
      },
    ],
    layers: "fall_2013_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 9,
  },
  {
    id: "spring_2014_upper",
    add: true,
    title: "Spring 2014",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { color: "rgb(45,41,160)", title: "Water Level Contours - Spring 2014" },
    ],
    layers: "spring_2014_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 10,
  },
  {
    id: "spring_2018_upper",
    add: true,
    title: "Spring 2018",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        color: "rgb(45,41,160)",
        title: "Water Level Contours - Spring 2018",
      },
    ],
    layers: "spring_2018_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 11,
  },
  {
    id: "fall_2018_upper",
    add: true,
    title: "Fall 2018",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        color: "rgb(45,41,160)",
        title: "Water Level Contours - Fall 2018",
      },
    ],
    layers: "fall_2018_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 12,
  },
  {
    id: "spring_2019_upper",
    add: true,
    title: "Spring 2019",
    checked: false,
    selector: "contourSelector",
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        color: "rgb(45,41,160)",
        title: "Water Level Contours - Spring 2019",
      },
    ],
    layers: "spring_2019_upper",
    map: "/var/www/sgmadms/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 13,
  },
];

export { map_initial_state, map_layers };
