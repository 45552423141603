import React, { Component } from "react";
import { Popup } from "react-leaflet";
import CircleMarker from "./CircleMarker";
import L from "leaflet";
import MarkerClusterGroup from "./MarkerCluster";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

class PageLayer extends Component {
  iconCreateFunction = (cluster) => {
    return new L.divIcon({
      html: "<div><span>" + cluster.getChildCount() + "</span></div>",
      className: "marker-cluster marker-cluster-" + this.props.clusterColor,
      iconSize: new L.Point(40, 40),
    });
  };

  mapPosition(item) {
    return [item.geometry.coordinates[1], item.geometry.coordinates[0]];
  }

  render() {
    const {
      legend,
      clusterColor,
      features,
      title,
      link,
      agencies,
      markerSize,
      markerClick,
    } = this.props;

    return (
      <MarkerClusterGroup
        legend={legend}
        clusterColor={clusterColor}
        disableClusteringAtZoom={0}
        spiderfyOnMaxZoom={false}
        iconCreateFunction={this.iconCreateFunction}
      >
        {features.map((item) => {
          if (!item.geometry) return null;

          return (
            <CircleMarker
              key={item.dms_site_id}
              color={clusterColor}
              radius={markerSize}
              position={this.mapPosition(item)}
              center={this.mapPosition(item)}
            ></CircleMarker>
          );
        })}
      </MarkerClusterGroup>
    );
  }
}

PageLayer = connect(
  (state) => ({
    authState: state.auth,
  }),
  null
)(PageLayer);

export default withRouter(PageLayer);
