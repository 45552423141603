import React, { Children, cloneElement } from "react";
import LegendLayers from "./LegendLayers";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { MapControl, withLeaflet, ControlledLayer } from "react-leaflet";
import L from "leaflet";
import * as locatecontrol from "leaflet.locatecontrol"; // eslint-disable-line
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";

class BaseLayer extends ControlledLayer {
    constructor(props) {
        super(props);
        this.contextValue = {
            ...props.leaflet,
            layerContainer: {
                addLayer: this.addLayer.bind(this),
                removeLayer: this.removeLayer.bind(this)
            }
        };
    }

    addLayer = layer => {
        this.layer = layer; // Keep layer reference to handle dynamic changes of props
        const { addBaseLayer, checked, name } = this.props;
        addBaseLayer(layer, name, checked);
    };
}

class Overlay extends ControlledLayer {
    constructor(props) {
        super(props);
        this.contextValue = {
            ...props.leaflet,
            layerContainer: {
                addLayer: this.addLayer.bind(this),
                removeLayer: this.removeLayer.bind(this)
            }
        };
    }

    addLayer = layer => {
        this.layer = layer; // Keep layer reference to handle dynamic changes of props
        const { addOverlay, checked, name } = this.props;
        addOverlay(layer, name, checked);
    };
}

class Overlay2 extends ControlledLayer {
    constructor(props) {
        super(props);
        this.contextValue = {
            ...props.leaflet,
            layerContainer: {
                addLayer: this.addLayer.bind(this),
                removeLayer: this.removeLayer.bind(this)
            }
        };
    }

    addLayer = layer => {
        this.layer = layer; // Keep layer reference to handle dynamic changes of props
        const { addOverlay2, checked, name } = this.props;
        addOverlay2(layer, name, checked);
    };
}

class CLayersControl extends MapControl {
    constructor(props) {
        super(props);
        this.controlProps = {
            addBaseLayer: this.addBaseLayer.bind(this),
            addOverlay: this.addOverlay.bind(this),
            addOverlay2: this.addOverlay2.bind(this),
            leaflet: props.leaflet,
            removeLayer: this.removeLayer.bind(this),
            removeLayerControl: this.removeLayerControl.bind(this)
        };
    }

    createLeafletElement(props) {
        const { children: _children, controlPosition, viewOnly, ...options } = props;

        // FIXME: https://github.com/smeijer/leaflet-geosearch/issues/196
        const search = new GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: "bar"
        });

        if (!viewOnly) {
            this.props.leaflet.map.addControl(search);
            this.props.leaflet.map.addControl(L.control.locate({ position: controlPosition }));
        }

        /*options.sections = [{ title: "Alert Layers", html: "<i>Coming Soon...</i>" }];*/
        return new LegendLayers(undefined, undefined, options);
    }

    componentWillUnmount() {
        setTimeout(() => {
            super.componentWillUnmount();
        }, 0);
    }

    addBaseLayer(layer, name, checked = false) {
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer);
        }
        this.leafletElement.addBaseLayer(layer, name);
    }

    addOverlay(layer, name, checked = false) {
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer);
        }
        this.leafletElement.addOverlay(layer, name);
    }

    addOverlay2(layer, name, checked = false) {
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer);
        }
        this.leafletElement.addOverlay2(layer, name);
    }

    removeLayer(layer) {
        if (this.props.leaflet.map != null) {
            this.props.leaflet.map.removeLayer(layer);
        }
    }

    removeLayerControl(layer) {
        if (layer) this.leafletElement.removeLayer(layer);
    }

    render() {
        const children = Children.map(this.props.children, child => {
            return child ? cloneElement(child, this.controlProps) : null;
        });

        return <>{children}</>;
    }
}
const CollapsibleLayersControl = withLeaflet(CLayersControl);

CollapsibleLayersControl.BaseLayer = BaseLayer;
CollapsibleLayersControl.Overlay = Overlay;
CollapsibleLayersControl.Overlay2 = Overlay2;

export default CollapsibleLayersControl;