import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import WarningDeleteDialog from "../../api/warningDeleteDialogHOC";
import MeasurementsHOC from "../../api/measurementsHOC";
import NotReady from "../common/NotReady";
import requestConfig from "../../api/fetchConfig";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid " + theme.palette.primary.main,
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    marginBottom: 16,
    width: "calc(50% - 16px)",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
});

class ImportMonitoringHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 10,
      showDelete: false,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (authState && authState.user && authState.user.role === "Agency") {
      history.push("/dashboard");
    }
  }

  columnData(id) {
    const groundwaterLevelDataCols = [
      {
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site",
        allowSort: true,
      },
      {
        id: "measurement_date",
        numeric: false,
        label: "Measurement Date",
        allowSort: true,
      },
      {
        id: "measurement_time",
        numeric: false,
        label: "Measurement Time",
        allowSort: true,
      },
      {
        id: "measurement_depth",
        numeric: true,
        label: "Measurement Depth",
        allowSort: true,
      },
      {
        id: "surf_elev",
        numeric: true,
        label: "Surface Elevation",
        allowSort: true,
      },
      {
        id: "vert_datum",
        numeric: false,
        label: "Vertical Datum",
        allowSort: true,
      },
      {
        id: "meas_method",
        numeric: false,
        label: "Measurement Method",
        allowSort: true,
      },
      {
        id: "meas_accuracy",
        numeric: false,
        label: "Measurement Accuracy",
        allowSort: true,
      },
      {
        id: "quest_meas",
        numeric: false,
        label: "Questionable Measurement",
        allowSort: true,
      },
      {
        id: "measured_by",
        numeric: false,
        label: "Measured By",
        allowSort: true,
      },
      {
        id: "submitted",
        numeric: true,
        label: "Submitted",
        allowSort: true,
      },
    ];

    const waterQualityDataCols = [
      {
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
      },
      {
        id: "meas_date",
        numeric: false,
        label: "Measurement Date",
        allowSort: true,
      },
      {
        id: "meas_time",
        numeric: false,
        label: "Measurement Time",
        allowSort: true,
      },
      {
        id: "lab_name",
        numeric: false,
        label: "Lab Name",
        allowSort: true,
      },
      {
        id: "analytical_method",
        numeric: false,
        label: "Analytical Method",
        allowSort: true,
      },
      {
        id: "characteristic",
        numeric: false,
        label: "Characteristic (Chemical)",
        allowSort: true,
      },
      {
        id: "result_value",
        numeric: true,
        label: "Result Value",
        allowSort: true,
      },
      {
        id: "result_units",
        numeric: false,
        label: "Result Units",
        allowSort: true,
      },
      {
        id: "result_qualifier",
        numeric: false,
        label: "Result Qualifier",
        allowSort: true,
      },
      {
        id: "sampled_by",
        numeric: false,
        label: "Sampled By",
        allowSort: true,
      },
      {
        id: "meas_comments",
        numeric: false,
        label: "Comments",
        allowSort: true,
      },
      {
        id: "submitted",
        numeric: true,
        label: "Submitted",
        allowSort: true,
      },
    ];

    const surfaceWaterCols = [
      {
        id: "dms_site_id",
        numeric: true,
        label: "Subsidence Site",
        allowSort: true,
      },
      {
        id: "meas_date",
        numeric: false,
        label: "Measurement Date",
        allowSort: true,
      },
      {
        id: "meas_time",
        numeric: false,
        label: "Measurement Time",
        allowSort: true,
      },
      {
        id: "ua_compaction",
        numeric: true,
        label: "Compaction",
        allowSort: true,
      },
      {
        id: "meas_elevation",
        numeric: true,
        label: "Measurement Elevation",
        allowSort: true,
      },
      {
        id: "total_subsidence",
        numeric: true,
        label: "Total Subsidence",
        allowSort: true,
      },
      {
        id: "meas_accuracy",
        numeric: true,
        label: "Measurement Accuracy",
        allowSort: true,
      },
      {
        id: "meas_comments",
        numeric: false,
        label: "Measurement Comments",
        allowSort: true,
      },
      {
        id: "submitted",
        numeric: true,
        label: "Submitted",
        allowSort: true,
      },
    ];

    const subsidenceCols = [
      {
        id: "dms_site_id",
        numeric: true,
        label: "Subsidence Site",
        allowSort: true,
      },
      {
        id: "meas_date",
        numeric: false,
        label: "Measurement Date",
        allowSort: true,
      },
      {
        id: "meas_time",
        numeric: false,
        label: "Measurement Time",
        allowSort: true,
      },
      {
        id: "ua_compaction",
        numeric: true,
        label: "Compaction",
        allowSort: true,
      },
      {
        id: "meas_elevation",
        numeric: true,
        label: "Measurement Elevation",
        allowSort: true,
      },
      {
        id: "total_subsidence",
        numeric: true,
        label: "Total Subsidence",
        allowSort: true,
      },
      {
        id: "meas_accuracy",
        numeric: true,
        label: "Measurement Accuracy",
        allowSort: true,
      },
      {
        id: "meas_comments",
        numeric: false,
        label: "Measurement Comments",
        allowSort: true,
      },
      {
        id: "submitted",
        numeric: true,
        label: "Submitted",
        allowSort: true,
      },
    ];

    const streamCols = [
      {
        id: "dms_site_id",
        numeric: true,
        label: "Subsidence Site",
        allowSort: true,
      },
      {
        id: "meas_date",
        numeric: false,
        label: "Measurement Date",
        allowSort: true,
      },
      {
        id: "meas_time",
        numeric: false,
        label: "Measurement Time",
        allowSort: true,
      },
      {
        id: "stream_stage",
        numeric: true,
        label: "Stream Stage",
        allowSort: true,
      },
      {
        id: "meas_comments",
        numeric: false,
        label: "Measurement Comments",
        allowSort: true,
      },
      {
        id: "submitted",
        numeric: true,
        label: "Submitted",
        allowSort: true,
      },
    ];

    if (id === "wellsitemeasurements") {
      return groundwaterLevelDataCols;
    } else if (id === "waterqualitymeasurements") {
      return waterQualityDataCols;
    } else if (id === "surfacewatermeasurements") {
      return surfaceWaterCols;
    } else if (id === "subsidencemeasurements") {
      return subsidenceCols;
    } else if (id === "streammonitoring") {
      return streamCols;
    } else {
      return [];
    }
  }

  isEnabled(id, appConfig) {
    const { history } = this.props;
    let enabled = false;

    if (id === "wellsitemeasurements" && appConfig.WLM.enabled) {
      enabled = true;
    } else if (id === "waterqualitymeasurements" && appConfig.WQM.enabled) {
      enabled = true;
    } else if (id === "subsidencemeasurements" && appConfig.SM.enabled) {
      enabled = true;
    } else if (id === "streammonitoring" && appConfig.ADMIN.STMS.enabled) {
      enabled = true;
    }

    if (!enabled) {
      history.push("/dashboard");
    }
  }

  handleRequestSort = (event, property) => {
    const { existingData } = this.state;

    const orderBy = property;
    let order = "desc";

    const colType = this.props.match.params["id"];

    const columnData = this.columnData(colType);

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    existingData.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  handleAPIChange(data) {
    if (data.user_role === "GSP Representative") {
      this.setState({ authorized: true });
    }

    this.setState({
      existingData: data.measurements,
      submitted: data.submitted,
      gsp: data.gsp,
      water_year: data.water_year,
      showDelete: false,
    });
  }

  render() {
    const { classes, authState } = this.props;
    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      authorized,
      showDelete,
      existingData,
      gsp,
      water_year,
      submitted,
      appConfig,
      isFetching,
    } = this.state;

    let histName = "";
    const histId = this.props.match.params["id"];
    const gspId = parseInt(this.props.match.params["gsp"]);

    let token = authState && authState.user ? authState.user.auth_token : "";

    //get the config data
    if (token !== "" && appConfig === undefined && !isFetching) {
      this.setState({ ...this.state, isFetching: true });
      requestConfig(token).then((data) => {
        this.setState({ appConfig: data, isFetching: false });
        this.isEnabled(histId, data);
      });
    }

    if (histId === "wellsitemeasurements") {
      histName = "Groundwater Level";
    } else if (histId === "waterqualitymeasurements") {
      histName = "Water Quality";
    } else if (histId === "surfacewatermeasurements") {
      histName = "Surfacewater - Groundwater Interaction";
    } else if (histId === "subsidencemeasurements") {
      histName = "Subsidence Monitoring";
    }

    let level1 = "Associated GSP's";
    let level1link = "/import";
    let level2 = histName;
    let level2link = "/importHistory/" + histId + "/" + gspId;
    let level3 = "";
    let level3link = "";

    if (this.props.location.pathname.indexOf("gspReportingHistory") > -1) {
      level1 = "GSP Reporting Status";
      level1link = "/gspreportingstatus";
      level2 = histName;
      level2link = "/gspReportingHistory/" + histId + "/" + gspId;
    } else if (
      this.props.location.pathname.indexOf("importMonitoringHistory") > -1
    ) {
      level1 = "Associated GSP's";
      level1link = "/import";
      level2 = "Import Monitoring Data";
      level2link = "/import/" + gspId;
      level3 = histName;
      level3link = "/importMonitoringHistory/" + histId + "/" + gspId;
    }

    if (existingData === undefined || appConfig === undefined) {
      return (
        <AppContainer authenticated>
          <MeasurementsHOC
            apitarget={histId}
            gsp_id={gspId}
            handler={this.handleAPIChange.bind(this)}
          />

          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              {level3 === "" ? (
                <BreadcrumbNav
                  level1={level1}
                  level1link={level1link}
                  level2={level2}
                  level2link={level2link}
                />
              ) : (
                <BreadcrumbNav
                  level1={level1}
                  level1link={level1link}
                  level2={level2}
                  level2link={level2link}
                  level3={level3}
                  level3link={level3link}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {gsp && gsp.name ? gsp.name + ": " : ""}
                {histName} ({water_year && water_year.water_year})
              </Typography>
            </Grid>
            {authorized && !submitted && existingData.length > 0 && (
              <Grid item xs={12}>
                <Button
                  className={classes.buttons}
                  style={{
                    backgroundColor:
                      authorized && !submitted ? "primary" : "#7ed2f5",
                    color: "white",
                  }}
                  disabled={!authorized || submitted}
                  onClick={() => this.setState({ showDelete: true })}
                >
                  Delete Existing Records
                </Button>
              </Grid>
            )}

            {histId === "wellsitemeasurements" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData(histId)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {existingData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.dms_site_id}</TableCell>
                            <TableCell>{item.measurement_date}</TableCell>
                            <TableCell>{item.measurement_time}</TableCell>
                            <TableCell>{item.measurement_depth}</TableCell>
                            <TableCell>{item.surf_elev}</TableCell>
                            <TableCell>{item.vert_datum}</TableCell>
                            <TableCell>{item.meas_method}</TableCell>
                            <TableCell>{item.meas_accuracy}</TableCell>
                            <TableCell>{item.quest_meas}</TableCell>
                            <TableCell>{item.measured_by}</TableCell>
                            <TableCell>
                              {item.submitted ? "True" : "False"}
                            </TableCell>
                          </TableRow>
                        ))}
                      {existingData.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {existingData.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={existingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {histId === "waterqualitymeasurements" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData(histId)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {existingData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.dms_site_id}</TableCell>
                            <TableCell>{item.meas_date}</TableCell>
                            <TableCell>{item.meas_time}</TableCell>
                            <TableCell>{item.lab_name}</TableCell>
                            <TableCell>{item.analytical_method}</TableCell>
                            <TableCell>{item.characteristic}</TableCell>
                            <TableCell>{item.result_value}</TableCell>
                            <TableCell>{item.result_units}</TableCell>
                            <TableCell>{item.result_qualifier}</TableCell>
                            <TableCell>{item.sampled_by}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                            <TableCell>
                              {item.submitted ? "True" : "False"}
                            </TableCell>
                          </TableRow>
                        ))}
                      {existingData.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {existingData.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={existingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {histId === "surfacewatermeasurements" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData(histId)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {existingData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.dms_site_id}</TableCell>
                            <TableCell>{item.meas_date}</TableCell>
                            <TableCell>{item.meas_time}</TableCell>
                            <TableCell>{item.ua_compaction}</TableCell>
                            <TableCell>{item.meas_elevation}</TableCell>
                            <TableCell>{item.total_subsidence}</TableCell>
                            <TableCell>{item.meas_accuracy}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                            <TableCell>
                              {item.submitted ? "True" : "False"}
                            </TableCell>
                          </TableRow>
                        ))}
                      {existingData.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {existingData.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={existingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {histId === "subsidencemeasurements" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData(histId)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {existingData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.dms_site_id}</TableCell>
                            <TableCell>{item.meas_date}</TableCell>
                            <TableCell>{item.meas_time}</TableCell>
                            <TableCell>{item.ua_compaction}</TableCell>
                            <TableCell>{item.meas_elevation}</TableCell>
                            <TableCell>{item.total_subsidence}</TableCell>
                            <TableCell>{item.meas_accuracy}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                            <TableCell>
                              {item.submitted ? "True" : "False"}
                            </TableCell>
                          </TableRow>
                        ))}
                      {existingData.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {existingData.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={existingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {histId === "streammonitoring" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={this.columnData(histId)}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {existingData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.dms_site_id}</TableCell>
                            <TableCell>{item.meas_date}</TableCell>
                            <TableCell>{item.meas_time}</TableCell>
                            <TableCell>{item.stream_stage}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                            <TableCell>
                              {item.submitted ? "True" : "False"}
                            </TableCell>
                          </TableRow>
                        ))}
                      {existingData.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Historical Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {existingData.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={existingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            <WarningDeleteDialog
              cancelAction={() => this.setState({ showDelete: false })}
              open={showDelete}
              title="Delete Data"
              confirmText="Delete Data"
              text={"Are you sure you wish to delete this data?"}
              datatarget={histId}
              gsp={gspId}
              handlerAction={this.handleAPIChange.bind(this)}
            />
          </Grid>
        </AppContainer>
      );
    }
  }
}
ImportMonitoringHistory = connect((state, ownProps) => ({
  authState: state.auth,
}))(ImportMonitoringHistory);

export default withStyles(styles)(ImportMonitoringHistory);
